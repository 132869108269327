<template>
    <div class="bg xjxq">
        <div class="card-bg">
            <div class="card-header ">
                <div class=" topLabel-center">{{dictValue}}</div>
                <div class="topCenterDesc  flex justify_content_center">
                    <div class="bold" style="font-size: 20px">
                        ¥
                    </div>
                    <div class="topLabel-right">
                        {{item.tranAmount}}
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <van-field label="记录时间"
                       label-width="2rem"
                       :value="item.tranTime"
                       readonly
                       input-align="right"
                       class="time-label"/>
        </div>
        <div class="flex justify_content_space_between bottom">
            <div class="bottom-btn" @click="toDelete()">删除</div>
            <div class="bottom-btn" @click="toEdit()">修改</div>
        </div>
    </div>
</template>

<script>
    import {getAccountInfo,deleteAccount} from "../../utils/api";
    import {Toast,Dialog} from "vant";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";

    export default {
        name: "transactionDetails",
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 3) {
                    return value.slice(0,3) + '...'
                }
                return value
            }
        },
        data(){
            return{
                transactionID:'',
                item:{},
                dictValue:'',
                tranType:[]
            }
        },
        methods:{
            toDelete(){
                Dialog.confirm({
                    title: '删除记录',
                    message: '是否确认删除',
                }).then(() => {
                    // on confirm
                    this.toDeleteAccount();
                }).catch(() => {
                    // on cancel
                });
            },
            toDeleteAccount(){
                deleteAccount({
                    'id':this.transactionID
                }).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.$router.go(-1)
                        }
                    }
                }).catch(()=>{
                })
            },
            toEdit(){
                this.$store.dispatch('keepAliveState/addKeepAliveAction', 'editTransacrionDetails')
                this.$router.push({name:'editTransacrionDetails',query:{'transactionID':this.transactionID}})
            },
            toGetTransactionInfo(){
                getAccountInfo({
                    'id':this.transactionID
                }).then(res=>{
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.item = res.data.accountBook;
                            this.toGetDictValue(this.item.tranType)
                        }
                    }
                }).catch(()=>{
                })
            },
            toGetDictValue(dictKey){
                for (var item of this.tranType){
                    if (dictKey == item.dictKey){
                        this.dictValue = item.dictValue;
                    }
                }
            },

        },
        mounted() {
            this.transactionID = this.$route.query.transactionID;
            this.tranType = JSON.parse(common.getItem('tranType'))
            this.toGetTransactionInfo();
            uwStatisticAction('/transactionDetails','交易详情')
        },
        activated () {
            this.toGetTransactionInfo();
        }
    }
</script>

<style lang="less" scoped>
    .bg{
        width: 100%;
        height: 100%;
        background: #F7F8F9;
        .card-bg{
            background: white;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            .card-header{
                height:144px;
            }
            .topLabel-center{
                padding-top: 44px;
                text-align: center;
                font-size: 17px;
                color: #3F7C53;
            }
            .topLabel-right{
                text-align: center;
                font-weight: bold;
                font-size: 30px;
                line-height: 32px;
                color: #333333;
                margin-left: 3px;
            }
            .topCenterDesc{
                align-items: flex-end;
                margin-top: 10px;
            }

        }
        .line{
            margin:0 0.2rem;height: 0.5px;background: #eeeeee
        }
        .time-label{
            color: #333333;
            font-size: 15px;
        }
        .bank-label{
            color: #999999;font-weight: bold;border-bottom: solid 1px #eeeeee
        }
        .group-bg{
            height: 1rem;margin:0 0.2rem;
            .group-first{
                margin-top: 0.25rem;float: right
            }
            .group-second{
                text-align: left;color: red
            }
            .group-third{
                margin-top: 0.25rem;float: right
            }
            .group-fourth{
                text-align: left
            }
        }
        .changeLabel{
            text-align: right;
            /*width: 1.2rem;*/
            height:0.5rem;
            line-height: 0.5rem;
            margin-top: 0.25rem;
            border-radius: 0.05rem;
            color: blue;
            float: right;
            /*font-weight: bold*/
        }
        .bottom{
            margin: 12px;
        }
        .bottom-btn{
            width: calc(50% - 6px);
            height: 48px;
            background-color: white;
            line-height: 48px;
            text-align: center;
            font-size: 18px;
            color: #333333;
            border-radius: 4px;
            border: solid 1px #e5e5e5;
        }
    }

</style>
<style>
    .xjxq .time-label  .van-field__label{

        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999 !important;
    }
</style>
aa